import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vue-googlemaps/dist/vue-googlemaps.css";
import VueGoogleMaps from "vue-googlemaps";
var VueScrollTo = require("vue-scrollto");
import VueLazyload from "vue-lazyload";

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.css";
import "./assets/style.css";

Vue.use(VueLazyload, {
  lazyComponent: true
});
Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: "AIzaSyD6tKVSx1NfvbuYM9LeDawkWpxJv1NihwA",
    // Enable more Google Maps libraries here
    libraries: ["places"],
    // Use new renderer
    useBetaRenderer: false
  }
});

Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
