<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>

const Home = () => import(
  /* webpackChunkName : "Home" */ './views/Home'
)

export default {
  components: { Home }
};
</script>
